import { Box, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import APIManager from '../../lib/apiManager';
import moment from 'moment';
import { sistema } from "../../model/Sistema";
import { CheckCircleOutlined, Edit } from '@mui/icons-material';
import { Visibility } from "@mui/icons-material";
import EditandoModal from "./components/EditandoModal";

const Membresias = ({setBotonera}) => {

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);

  const [active, setActive] = useState(true);
  const [vigente, setVigente] = useState(true);
  const [nombre, setNombre] = useState('');
  // const [profesion, setProfesion] = useState('');
  // const [tipo, setTipo] = useState('');

  const [loading, setLoading] = useState(false);
  const [cambioEnFiltros, setCambioEnFiltros] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [editando, setEditando] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);

  const nuevoItem = {nombre: '', descripcion: '', fecha_vigencia_desde: new Date(), codigo: 'membresia', fecha_vigencia_hasta: null, restricciones: {}};

  const navigate = useNavigate();

  const columns = [
    {
      id: 'nombre',
      numeric: false,
      disablePadding: false,
      label: 'Nombre',
    },
    {
      id: 'vigenciaDesde',
      numeric: false,
      disablePadding: false,
      // valueFunc: val => val?.fecha_vigencia_desde?.substring(0, 10),
      valueFunc: val => val?.vigenciaDesde?.toISOString().substring(0, 10),
      label: 'Desde',
    },
    {
      id: 'vigenciaHasta',
      numeric: false,
      disablePadding: false,
      // valueFunc: val => val?.fecha_vigencia_hasta?.substring(0, 10),
      valueFunc: val => val?.vigenciaHasta?.toISOString().substring(0, 10),
      label: 'Hasta',
    },
    {
      id: 'diasStr',
      numeric: false,
      disablePadding: false,
      label: 'Días',
    },
    {
      id: 'sedesStr',
      numeric: false,
      disablePadding: false,
      label: 'Sedes',
    },
    {
      id: 'acciones',
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const valueFrom = (item, key) => {
    // console.log('valueFrom', item, key);
    switch (typeof item) {
      case 'undefined':
      case 'null':
      case 'string':
      case 'numeric':
      case 'boolean':
        return item;
      default:
        if (Array.isArray(item)) {
          return item.toString();
        }
    }
    if (typeof key === 'string') {
      if (!!item) {
        return item[key];
      } else {
        return item;
      }
    }
    if (Array.isArray(key)) {
      return arrayValuesFrom(item, key);
    }
  }

  const arrayValuesFrom = (item, keys) => {
    // console.log('arrayValuesFrom', item, keys);
    let value = Object.assign({}, item);
    keys.forEach(key => {
      let newValue = valueFrom(value, key);
      if (!!newValue) {
        value = newValue;
      }
    });
    return value;
  }

  const valueForRow = (row, column) => {
    // console.log('valueForRow', row, column);
    let value = '';
    if (!!column.valueFunc) {
      value = column.valueFunc(row);
    } else
    if (!!column.valueArray) {
      value = arrayValuesFrom(row, column.valueArray);
    } else
    if (column.id === 'activo')
    {
      value = row.activo ? 'SI' : 'NO';
    } else {
      value = valueFrom(row[column.id]);
      // value = row[column.id];
    }
    return value;
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const openItem = (event, row) => {
    setSelectedItem(row);
    setEditando(true);
    // navigate('/membresias/' + row.id);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const actualizarItems = () => {
    setLoading(true);
    setRows([]);
    setPage(0);
    setCambioEnFiltros(false);
    APIManager.getList('modosDeContratacion', {vencidos: !vigente}, (response) => {
      if (response.success) {
        response.data.listado.forEach(element => {
          element.vigenciaDesde = element.fecha_vigencia_desde ? new Date(element.fecha_vigencia_desde) : null;
          element.vigenciaHasta = element.fecha_vigencia_hasta ? new Date(element.fecha_vigencia_hasta) : null;
          element.restricciones = JSON.parse(element.restricciones);
          element.diasStr = element.restricciones?.diasDeLaSemana?.map((dia, valido) => dia ? moment().isoWeekday(valido).format('dddd') : false).filter(a => !!a);
          if (!element.diasStr) {
            element.diasStr = 'todos';
          } else {
            element.diasStr = element.diasStr.toString();
          }
          if (!!element.restricciones?.espaciosPermitidos) {
            element.sedesStr = 'Solo: ' + element.restricciones.espaciosPermitidos.map(id => sistema.espacios.find(item => item.id === id)?.nombre).toString();
          } else if (!!element.restricciones?.espaciosDenegados) {
            element.sedesStr = 'Todas excepto: ' + element.restricciones.espaciosDenegados.map(id => sistema.espacios.find(item => item.id === id)?.nombre).toString();
          } else {
            element.sedesStr = 'todas';
          }
        });
        window.listado = response.data.listado;
        setRows(response.data.listado);
        setLoading(false);
      }
    })
  }

  const editarItem = (item) => {
    console.log('editar item', item);
    APIManager.sendRequest('post', 'creditos', 'modificarModoDeContratacion', { modoDeContratacion: item }, (response) => {
      if (response.success) {
        // Modo de contratacion guardado correctamente!
        actualizarItems();
        setSuccessOpen(true);
      }
    });
  }

  const toggleActive = (event) => {
    setActive(!active);
  }

  const handleChangeNombre = (event) => {
    setNombre(event.target.value);
    setCambioEnFiltros(true);
  }

  const handleChangeActive = event => {
    setActive(event.target.checked);
    setCambioEnFiltros(true);
  }

  const handleChangeVigente = event => {
    setVigente(event.target.checked);
    setCambioEnFiltros(true);
  }

  const handleEnterKeyup = (event) => {
    if (event.code === 'Enter') {
      actualizarItems();
    }
  }

  useEffect(() => {
    sistema.actual.active = active;
    setBotonera([
      // { type: 'input', id: 'input-nombre', inputType: 'text', title: 'Nombre', label: 'Nombre', value: nombre, onChange: handleChangeNombre, onKeyUp: handleEnterKeyup },
      { key: 0, type: 'input', inputType: 'checkbox', title: 'Mostrar solo Membresías vigentes', label: 'Solo vigentes',  checked: vigente, onChange: handleChangeVigente },
      { key: 1, type: 'button', title: 'Buscar', label: 'Buscar', variant: (cambioEnFiltros ? 'contained' : 'outlined'), onClick: () => { actualizarItems() } },
      // { title: 'Nuevo', label: 'Nuevo', variant: 'outlined', onClick: () => { openItem(null, 'nuevo') } },
    ]);
  }, [active, vigente, nombre, cambioEnFiltros]);

  useEffect(() => {
    actualizarItems();
  }, [])

  return (
    <Box>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EditandoModal open={editando} setOpen={setEditando} columns={columns} selectedItem={selectedItem} setSelectedItem={editarItem} />
        <Dialog open={successOpen} onClose={() => {setSuccessOpen(false)}} setOpen={setSuccessOpen}>
          <DialogContent sx={{ bgcolor: "green" }}>
            <DialogTitle sx={{color: "white"}}>Operación confirmada</DialogTitle>
            <Stack alignItems="center" justifyContent="center">
              <CheckCircleOutlined sx={{ color: "white" }} fontSize="large" />
            </Stack>
          </DialogContent>
        </Dialog>
        <Typography padding={2} fontWeight="bold" fontSize="large">Lista de Membresías</Typography>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < rows.length}
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell> */}
                {columns.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {!!rows && rows.length > 0 ?
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => openItem(event, row.id ?? row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row.id)}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell> */}
                        {columns.map(column => <TableCell
                          // component="th"
                          key={column.id}
                          id={labelId}
                          // scope={columnIndex === 0 ? "row" : ""}
                          padding={column.disablePadding ? 'none' : 'normal'}
                          align={column.numeric ? 'right' : 'left'}
                        >
                          {column.id !== 'acciones'
                            ? valueForRow(row, column)
                            : <Stack direction="row">
                              <IconButton title="Editar" onClick={(event) => openItem(event, row)}><Edit /></IconButton>
                            </Stack>
                            }
                        </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              : (loading
                ? <TableBody><TableRow><TableCell colSpan={columns.length} padding="normal" align="center"><CircularProgress /></TableCell></TableRow></TableBody>
                : <TableBody><TableRow><TableCell colSpan={columns.length} align="center"><Typography>{'No hay datos para mostrar'}</Typography></TableCell></TableRow></TableBody>)
            }
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
    </Box>
  );
}

export default Membresias;