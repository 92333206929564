import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select } from '@mui/material';
import { Modal, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '@mui/material';
import { Box } from '@mui/material';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Stack } from '@mui/material';
import APIManager from '../../../../lib/apiManager';

const NuevoModal = ({ open, setOpen, selectedItem, columns, setSelectedItem }) => {
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');

  const navigate = useNavigate();


  const handleContinuar = () => {
    const nuevoItem = Object.assign({}, selectedItem, { nombre: nombre, descripcion: descripcion });
    setSelectedItem(nuevoItem);
    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleCancel = () => {
    handleClose();
  }

  const handleChangeNombre = (event) => {
    setNombre(event.target.value);
  }

  const handleChangeDescripcion = (event) => {
    setDescripcion(event.target.value);
  }

  useEffect(() => {
    setNombre(selectedItem?.nombre ?? '');
    setDescripcion(selectedItem?.descripcion ?? '');
  }, [selectedItem]);

  return (
    <Dialog
    open={open}
    aria-labelledby="nuevoContrato-dialog-title"
    aria-describedby="nuevoContrato-dialog-description"
  >
      <DialogTitle id="alert-dialog-title">
        Tipo de Documento
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="nuevoContrato-dialog-description">
           Ingrese los datos
        </DialogContentText>
        <Stack spacing={2} marginTop={2}>

          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel id="item-label">Nombre</InputLabel>
            <Input
              labelId="item"
              id="item-select"
              value={nombre}
              onChange={handleChangeNombre}
              label="Item"
            />
          </FormControl>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel id="item-label">Descripción</InputLabel>
            <Input
              labelId="item"
              id="item-select"
              value={descripcion}
              onChange={handleChangeDescripcion}
              label="Descripcion"
            />
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button onClick={handleContinuar} disabled={!nombre}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NuevoModal;