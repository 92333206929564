import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Notifications as NotificationsIcon, Menu as MenuIcon, ChevronLeft as ChevronLeftIcon, ShoppingCart, People, Dashboard, BarChart, Layers, LayersClear, StarBorder, StarBorderSharp, Inbox, ExpandLessSharp, ExpandMoreSharp } from "@mui/icons-material";
import { Avatar, Badge, Box, Button, Checkbox, Collapse, Container, CssBaseline, Divider, Drawer, FormControl, FormControlLabel, Grid, IconButton, Input, InputLabel, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Select, Toolbar, Typography } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import AppBar from '../../components/AppBar';
import NavBar from '../../components/NavBar';
import { menuFunction } from '../../mockup/menu';
import { orange } from '@mui/material/colors';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ExitToApp } from '@mui/icons-material';
import { sistema } from '../../model/Sistema';
import { Stack } from '@mui/material';
import APIManager from '../../lib/apiManager';

// const mdTheme = createTheme();
const mdTheme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ffbb88',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

// const menu = menuFunction();

const Main = ({menu, espacios, espacio, setEspacio, doLogout, botonera}) => {
  const [navOpen, setNavOpen] = useState(true);
  const [itemOpen, setItemOpen] = useState(false);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [avatarEl, setAvatarEl] = useState(null);
  const [navItemSelected, setNavItemSelected] = useState(null);
  const [navItemActual, setNavItemActual] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const usuario = sistema.usuario;

  const handleAvatarClick = (event) => {
    setAvatarEl(event.target);
    setAccountMenuOpen(!accountMenuOpen);
  }

  const cerrarSesion = () => {
    doLogout();
  }

  const toggleDrawer = () => {
    setNavOpen(!navOpen);
  };

  const toggleItem = (item) => {
    setItemOpen(!itemOpen);
  };

  const drawerWidth = 250;

  const handleMenuItemClick = (item) => {
    setNavItemActual(item);
    setNavItemSelected(item.nombre);
    navigate('/' + item.nombre);
  }

  const menuListItemsFrom = (menu) => {
    return <>
      {menu.map(
        (item, key) =>
          (item.nombre == 'separador')
            ? <Divider key={key} />
            : <ListItemButton key={key} onClick={() => handleMenuItemClick(item)} selected={navItemSelected === item.nombre}>
                <ListItemIcon><Dashboard /></ListItemIcon>
                <ListItemText primary={item.descripcion} />
            </ListItemButton>
          )
      }
    </>;
  }

  useEffect(() => {
    // const currentItem = menu.find((item) => location.pathname.startsWith('/' + item.nombre));
    const currentItem = menu.find((item) => location.pathname.split('/')[1] === item.nombre);
    if (!!currentItem) {
      setNavItemActual(currentItem);
      setNavItemSelected(currentItem.nombre);
    }

  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
     <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar drawerwidth={drawerWidth} position="absolute" open={navOpen}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                // ...(navOpen && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            {/* <Box sx={{width: drawerWidth}}></Box> */}
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              {/* <InputLabel id="espacio-select-label">Espacio</InputLabel> */}
              <Select
                labelId="espacio-select-label"
                id="espacio-select"
                value={espacio}
                label="Espacio"
                autoWidth
                onChange={(e) => {
                  setEspacio(e.target.value)
                }}
              >
                {espacios.map((espacio, index) => {
                  return (
                    <MenuItem key={index} value={espacio}>{espacio.descripcion}</MenuItem>
                  )
                })}
              </Select>
              {/* <Select
                // labelId="demo-simple-select-label"
                // id="demo-simple-select"
                value={espacioID}
                onChange={handleChange}
                // displayEmpty
                // inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value={6}>Palermo SOHO</MenuItem>
                <MenuItem value={2}>Palermo Hollywood</MenuItem>
                <MenuItem value={3}>Villa Crespo</MenuItem>
              </Select> */}
              {/* <FormHelperText>Without label</FormHelperText> */}
            </FormControl>
            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <IconButton
              onClick={handleAvatarClick}
              aria-controls={accountMenuOpen ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={accountMenuOpen ? 'true' : undefined}
            >
              <Avatar src={usuario.avatar} alt={usuario.descripcion}></Avatar>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Menu
          id="account-menu"
          anchorEl={avatarEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={{marginBlockStart: '1em'}}
          open={accountMenuOpen}
          onClose={() => setAccountMenuOpen(false)}
          onClick={() => setAccountMenuOpen(false)}
        >
          <MenuItem onClick={cerrarSesion}>
            <ExitToApp /> Cerrar sesión
          </MenuItem>
        </Menu>
        <NavBar drawerwidth={drawerWidth} variant="permanent" open={navOpen}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            {/* <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton> */}
          </Toolbar>
          <Divider />
          <List component="nav">
            {menuListItemsFrom(menu)}
          </List>
        </NavBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4}} >
            {!!botonera && (botonera.length > 0) &&
              <Paper sx={{ marginBlockEnd: '10px', p: 1}} >
                {/* {!!navItemActual && <Typography fontSize='1.5em'>{navItemActual.descripcion}</Typography>} */}
                <Stack spacing={2} direction="row" >
                  {botonera.map((item, index) => {
                    // console.log('botonera add', item);
                    if ((item.visible === undefined) || !!item.visible) {
                      switch (item.type) {
                        case 'input': {
                          switch (item.inputType) {
                            case 'checkbox':
                              return <FormControlLabel key={index} id={item.id}
                                control={<Checkbox checked={item.checked} onChange={!!item.onChange ? item.onChange : null} />}
                                label={item.label}
                                title={item.title}
                                // variant="standard" sx={{ minWidth: 120 }}
                              />;
                            default:
                              return <FormControl key={index} variant="standard" sx={{ minWidth: 120 }}>
                                <InputLabel id={item.id + "-label"}>{item.label}</InputLabel>
                                <Input type={item.inputType} labelid={item.id + "-label"} id={item.id} value={item.value} title={item.title} onChange={!!item.onChange ? item.onChange : null} onKeyUp={!!item.onKeyUp ? item.onKeyUp : null} />
                              </FormControl>;
                          }
                        }
                        case 'button':
                          return <Button key={index} variant={!!item.variant ? item.variant : 'default'} disabled={!!item.disabled} value={item.value} title={item.title} onClick={!!item.onClick ? item.onClick : null}>{!!item.label && item.label}</Button>
                        default:
                          if (!!item.control) {
                            return item.control;
                          }
                          return <Button key={index} variant={!!item.variant ? item.variant : 'default'} disabled={!!item.disabled} value={item.value} title={item.title} onClick={!!item.onClick ? item.onClick : null}>{!!item.label && item.label}</Button>
                      }
                    }
                  })}
                </Stack>
              </Paper>
            }
            {/* <Box height={'calc(100vh - 64px '+(!!botonera && (botonera.length > 0) ? " - 130px" : "") +")"} overflow="scroll"> */}
            <Box>
              <Outlet />
            </Box>
            {/* <Copyright sx={{ pt: 4 }} /> */}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Main;