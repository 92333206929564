import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, MenuItem, Select, TextField } from '@mui/material';
import { Modal, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '@mui/material';
import { Box } from '@mui/material';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Stack } from '@mui/material';
import APIManager from '../../../../lib/apiManager';
import { sistema } from '../../../../model/Sistema';
import moment, { isDate } from 'moment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Delete } from '@mui/icons-material';

const EditandoModal = ({ open, setOpen, selectedItem, columns, setSelectedItem }) => {
  const [codigo, setCodigo] = useState('');
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [vigenciaDesde, setVigenciaDesde] = useState(null);
  const [vigenciaHasta, setVigenciaHasta] = useState(null);
  const [diasDeLaSemana, setDiasDeLaSemana] = useState([]);
  const todosLosDias = [true, true, true, true, true, true, true];
  const [sedesPermitidas, setSedesPermitidas] = useState([]);
  const [sedesDenegadas, setSedesDenegadas] = useState([]);

  const [nuevoItem, setNuevoItem] = useState(null);
  const [confirmable, setConfirmable] = useState(false);

  const sedes = sistema.espacios;

  const navigate = useNavigate();

  const verificarConfirmable = () => {
    const restricciones = {};
    let diasIguales = true;
    if (!diasDeLaSemana.every(habilitado => !!habilitado)) {
      restricciones.diasDeLaSemana = diasDeLaSemana;
    }
    if (!!sedesDenegadas) {
      restricciones.sedesDenegadas = sedesDenegadas;
    }
    if (!!sedesPermitidas) {
      restricciones.sedesPermitidas = sedesPermitidas;
    }
    setNuevoItem(Object.assign({}, selectedItem, { codigo: codigo, nombre: nombre, descripcion: descripcion, fecha_vigencia_desde: vigenciaDesde, fecha_vigencia_hasta: vigenciaHasta, restricciones: restricciones }));
    setConfirmable(!!codigo && !!nombre && !!descripcion && !!vigenciaDesde && (vigenciaDesde >= new Date().setHours(0,0,0,0)));
  }

  const handleContinuar = () => {
    setSelectedItem(nuevoItem);
    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleCancel = () => {
    handleClose();
  }

  const handleChangeCodigo = (event) => {
    setCodigo(event.target.value);
  }

  const handleChangeNombre = (event) => {
    setNombre(event.target.value);
  }

  const handleChangeDescripcion = (event) => {
    setDescripcion(event.target.value);
  }

  const handleChangeVigenciaHasta = (newDate) => {
    setVigenciaHasta(new Date(newDate));
  }

  const handleChangeVigenciaDesde = (newDate) => {
    console.log('handleChangeVigenciaDesde', newDate, new Date(newDate));
    setVigenciaDesde(new Date(newDate));
  }

  const handleCheckDia = (event, dia, habilitado) => {
    console.log('handleCheckDia', dia, habilitado);
    setDiasDeLaSemana(diasDeLaSemana.map((value, key) => key === dia ? !habilitado : value));
  }

  useEffect(() => {
    setCodigo(selectedItem?.codigo ?? 'nueva' + moment(new Date()).format('yyyy.mm.dd'));
    setNombre(selectedItem?.nombre ?? '');
    setDescripcion(selectedItem?.descripcion ?? '');
    setVigenciaDesde(selectedItem?.fecha_vigencia_desde ?? null);
    setVigenciaHasta(selectedItem?.fecha_vigencia_hasta ?? null);
    setDiasDeLaSemana(selectedItem?.restricciones?.diasDeLaSemana ?? todosLosDias);
    setSedesDenegadas(selectedItem?.restricciones?.sedesDenegadas ?? null);
    setSedesPermitidas(selectedItem?.restricciones?.sedesPermitidas ?? null);
  }, [selectedItem]);

  useEffect(() => verificarConfirmable(), [codigo, nombre, descripcion, vigenciaDesde, vigenciaHasta, diasDeLaSemana, sedesDenegadas, sedesPermitidas]);

  useEffect(() => {sistema.actual = nuevoItem ?? {}}, [nuevoItem]);

  return (
    <Dialog
    open={open}
    aria-labelledby="nuevoContrato-dialog-title"
    aria-describedby="nuevoContrato-dialog-description"
  >
      <DialogTitle id="alert-dialog-title">
        Actualización de Membresía
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="nuevoContrato-dialog-description">
           Ingrese los datos
        </DialogContentText>
        <Stack spacing={2} marginTop={2}>

          <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel id="item-label">Código</InputLabel>
              <Input
                labelId="item"
                id="item-select"
                value={codigo}
                onChange={handleChangeCodigo}
                label="Item"
              />
          </FormControl>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel id="item-label">Nombre</InputLabel>
            <Input
              labelId="item"
              id="item-select"
              value={nombre}
              onChange={handleChangeNombre}
              label="Item"
            />
          </FormControl>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel id="item-label">Descripción</InputLabel>
            <Input
              labelId="item"
              id="item-select"
              value={descripcion}
              onChange={handleChangeDescripcion}
              label="Descripcion"
            />
          </FormControl>
          <Stack>
            {/* <FormControl variant="standard" sx={{ width: "100%" }}> */}
              <InputLabel id="item-label">Días de la semana permitidos</InputLabel>
              <Stack direction="row">
                {diasDeLaSemana.map((habilitado, dia) =>
                    // <FormControl variant="standard" sx={{ width: "100%" }}>
                      <FormControlLabel control={<Checkbox checked={habilitado} onChange={(event) => handleCheckDia(event, dia, habilitado)} />} label={moment().isoWeekday(dia).format('ddd')} />
                    // </FormControl>
                    )
                }
              </Stack>
            {/* </FormControl> */}
          </Stack>
          <Stack direction="row" spacing={1}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              {/* <InputLabel id="item-label">Vigente desde</InputLabel> */}
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Vigencia desde"
                  inputFormat="DD/MM/YYYY"
                  value={vigenciaDesde}
                  onChange={handleChangeVigenciaDesde}
                  minDate={new Date().setHours(0,0,0,0)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              {/* <InputLabel id="item-label">Vigente hasta</InputLabel> */}
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Vigencia hasta"
                  inputFormat="DD/MM/YYYY"
                  value={vigenciaHasta}
                  onChange={handleChangeVigenciaHasta}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <IconButton disabled={!vigenciaHasta} sx={{position: 'absolute', top: "0.3em", left: 180}} onClick={() => setVigenciaHasta(null)}><Delete color={!!vigenciaHasta ? 'error' : 'disabled'} /></IconButton>
            </FormControl>
          </Stack>

        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button onClick={handleContinuar} disabled={!confirmable}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditandoModal;