import { Box, Checkbox, CircularProgress, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import APIManager from '../../lib/apiManager';
import { Draw as DrawIcon, PictureAsPdf, UploadFile, Visibility } from '@mui/icons-material';
import { sistema } from "../../model/Sistema";
import { sendRequest } from "../../lib/serverAPI";

const Contratos = ({setBotonera, espacios}) => {

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(!!sistema && !!sistema.actual ? (sistema.actual.pageParaContratos ?? 0) : 0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);

  /* Filtros */
  const [nombre, setNombre] = useState(!!sistema && !!sistema.actual ? sistema.actual.nombreParaContratos : '');
  const [pendientes, setPendientes] = useState(false);
  const [vencidos, setVencidos] = useState(false);

  const [cambioEnFiltros, setCambioEnFiltros] = useState(false);
  const [loading, setLoading] = useState(false);

  const [contratoParaUploadPDF, setContratoParaUploadPDF] = useState(null);
  const inputRef = useRef(null);

  const navigate = useNavigate();

  const [usuario, setUsuario] = useState(null);

  const columns = [
    {
      id: 'tipo',
      numeric: false,
      disablePadding: false,
      label: 'Tipo',
    },
    {
      id: 'cliente',
      numeric: false,
      disablePadding: true,
      label: 'Cliente',
    },
    {
      id: 'numero',
      numeric: false,
      disablePadding: true,
      label: 'Numero',
    },
    {
      id: 'fecha_vigencia_desde',
      numeric: false,
      disablePadding: true,
      label: 'Desde',
    },
    {
      id: 'fecha_vigencia_hasta',
      numeric: false,
      disablePadding: true,
      label: 'Vencimiento',
    },
    {
      id: 'space_name',
      numeric: false,
      disablePadding: false,
      label: 'Espacio',
    },
    {
      id: 'vigente',
      numeric: false,
      disablePadding: true,
      label: 'Vigente',
    },
    {
      id: 'aprobado',
      numeric: false,
      disablePadding: true,
      label: 'Aprobado',
    },
    {
      id: 'acciones',
      numeric: false,
      disablePadding: true,
      label: ''
    }
  ];

  const isSelected = (name) => selected.indexOf(name) !== -1;

  function puedeAdinistrarEspacio(espacioID) {
    return espacios.find(item => item.id === espacioID);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const openItem = (event, id, item) => {
    console.log('abriendo contrato', item);
    navigate('/contratos/' + id);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    sistema.actual.pageParaContratos = newPage;
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const actualizarItems = () => {
    setCambioEnFiltros(false);
    setLoading(true);
    setRows([]);
    APIManager.getList('contratos', {orderBy: 'fecha_vigencia_desde, cliente', vencidos: vencidos, pendientes: pendientes, cliente: nombre}, (response) => {
      if (response.success) {
        setRows(response.data.listado);
        setLoading(false);
      }
    })
  }

  const handleChangeNombre = (event) => {
    setCambioEnFiltros(true);
    sistema.actual.nombreParaContratos = event.target.value;
    setNombre(event.target.value);
  }

  const handleChangePendientes = (event) => {
    setCambioEnFiltros(true);
    setPendientes(event.target.checked);
  }

  const handleChangeVencidos = (event) => {
    setCambioEnFiltros(true);
    setVencidos(event.target.checked);
  }

  const buscar = () => {
    sistema.actual.pageParaContratos = 0;
    setPage(0);
    actualizarItems();
  }

  const handleNombreKeyup = (event) => {
    if (event.code === 'Enter') {
      buscar();
    }
  }

  const handleFirmar = (contrato) => {
    navigate('/firmarContrato/' + contrato.id);
  }

  const handleVerPDF = (contrato) => {
    window.open(contrato.documentoURL);
    // navigate('/firmarContrato/' + contrato.id);
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const uploadPDF = async (event) => {
    const pdfFiles = event.target.files;
    if (!!contratoParaUploadPDF && (pdfFiles.length > 0)) {
      const pdfFile = pdfFiles[0];
      const base64file = await toBase64(pdfFile);
      // APIManager.sendRequest('post', 'contratos', 'grabarDocumento', { documento: pdfFile, contrato: contratoParaUploadPDF }, (response) => {
      APIManager.sendRequest('post', 'contratos', 'grabarDocumento', { documento: base64file, isBase64: true, contrato: contratoParaUploadPDF }, (response) => {
      // APIManager.sendRequest('post', 'contratos', 'grabarDocumento', formData, (response) => {
        window.response = response;
        event.target.files = null;
      });
    }

  }

  const handleUploadPDF = (contrato) => {
    setContratoParaUploadPDF(contrato);
    inputRef.current.click();
  }

  useEffect(() => {
    setBotonera([
      { type: 'input', id: 'input-nombre', inputType: 'text', title: 'Nombre de cliente', label: 'Nombre', value: nombre, onChange: handleChangeNombre, onKeyUp: handleNombreKeyup },
      { type: 'input', id: 'input-pendientes', inputType: 'checkbox', title: 'Ver solo los pendientes', label: 'Solo Pendientes', checked: pendientes, onChange: handleChangePendientes },
      { type: 'input', id: 'input-vencidos', inputType: 'checkbox', title: 'Incluir los vencidos', label: 'Incluir Vencidos', checked: vencidos, onChange: handleChangeVencidos },
      { title: 'Buscar', label: 'Buscar', variant: (cambioEnFiltros ? 'contained' : 'outlined'), onClick: () => { buscar(); } },
      { title: 'Nuevo', label: 'Nuevo', variant: 'outlined', onClick: () => { openItem(null, 'nuevo') } },
    ]);
  }, [nombre, pendientes, vencidos, cambioEnFiltros]);

  useEffect(() => {
    setUsuario(sistema.usuario);
    actualizarItems();
  }, []);

  return (
    <Box>
      <Paper sx={{ width: '100%', padding: '14px' }} >
        <Typography>Lista de Contratos</Typography>
        <input ref={inputRef} accept="application/pdf" style={{display: 'none'}} type="file" onChange={uploadPDF} />
        <TableContainer sx={{maxHeight: 'calc(100vh - 300px)'}}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < rows.length}
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell> */}
                {columns.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {!!rows && (rows.length > 0)
              ? <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => setSelected([row.id])}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell> */}
                        {columns.map(((column, columnIndex) =>
                          <TableCell
                            // component="th"
                            id={labelId}
                            // scope={columnIndex === 0 ? "row" : ""}
                            padding={column.disablePadding ? 'none' : 'normal'}
                            align={column.numeric ? 'right' : 'left'}
                          >
                            {column.id !== 'acciones'
                              ? row[column.id]
                              : <Stack direction="row">
                                <IconButton title="Ver Detalles" onClick={(event) => openItem(event,row.id,row)}><Visibility /></IconButton>
                                {!!row.approver_id && !!row.template_id && !row.firmante_firma && <IconButton title="Firmar" disabled={!espacios.find(item => item.id === row.space_id)} onClick={() => handleFirmar(row)}><DrawIcon /></IconButton>}
                                {!!row.documentoURL && <IconButton title="Ver PDF" disabled={!espacios.find(item => item.id === row.space_id)} onClick={() => handleVerPDF(row)}><PictureAsPdf /></IconButton>}
                                {!!usuario?.is_superuser && !!row.approver_id && <IconButton title="Reemplazar PDF" onClick={() => handleUploadPDF(row)}><UploadFile /></IconButton>}
                              </Stack>
                            }
                          </TableCell>
                        ))}
                        {/* <TableCell
                        // component="th"
                        id={labelId}
                        scope="row"
                        padding={columns[0]?.disablePadding ? 'none' : 'normal'}
                      >
                        {row.nombre}
                      </TableCell>
                      <TableCell align="right">{row.descripcion}</TableCell>
                      <TableCell align="right">{row.vigente ? 'SI' : 'NO'}</TableCell>
                      <TableCell align="right">{row.aprobado ? 'SI' : 'NO'}</TableCell> */}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              : (loading
                ? <TableBody><TableRow><TableCell colSpan={columns.length} padding="normal" align="center"><CircularProgress /></TableCell></TableRow></TableBody>
                : <TableBody><TableRow><TableCell colSpan={columns.length} padding="normal" align="center"><Typography>{'No hay datos para mostrar'}</Typography></TableCell></TableRow></TableBody>)
            }
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
    </Box>
  );
}

export default Contratos;